import { useEffect, useRef, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import dayjs from "dayjs";
import DayViewColumn from "./DayViewColumn";
import { mqtt5 } from "aws-iot-device-sdk-v2";
import { useWebsocketContext } from "../providers/WebsocketProvider";
import { toUtf8 } from "@aws-sdk/util-utf8-browser";

const MAX_RIDERS_PER_PAGE = 18;
const MAX_LESSONS_PER_PAGE = 3;

export type BoardEntry = {
  boardId: string;
  personId: string;

  firstName: string;
  lastName: string;

  horseId: string;
  horseName: string;
  horseChangedTime: string;

  blanket: boolean;
  horseIn: boolean;
  feedAfterClass: boolean;
  notes: string;

  entryModifications: string;
  riderAbsent: boolean;

  saddle: {
    saddle: string;
    pads: string;
    additions: string;
  };
};

export type LessonOnBoard = {
  lessonId: number;
  lessonTime: string;
  lessonNotes: string;
  lessonLevel?: number;
  program: string;
  entries: BoardEntry[];
  notes: string;
};

export type BoardDay = LessonOnBoard[];

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function concatIfBothNotEmpty(str1: string, str2: string) {
  if (str1.trim() !== "" && str2.trim() !== "") {
    return str1 + ", " + str2;
  } else if (str1) {
    return str1;
  }

  return str2;
}

function determineColums(day: BoardDay) {
  let page = 0;
  let currentNumRiders = 0;
  let currentNumLessons = 1;

  const cols: BoardDay[] = [[]];
  for (let i = 0; i < day?.length; i++) {
    if (
      currentNumRiders + day[i].entries.length <= MAX_RIDERS_PER_PAGE &&
      currentNumLessons <= MAX_LESSONS_PER_PAGE
    ) {
      currentNumRiders += day[i].entries.length;
      currentNumLessons += 1;
      cols[page].push(day[i]);
      continue;
    }

    currentNumRiders = day[i].entries.length;
    currentNumLessons = 2;
    page += 1;
    cols.push([day[i]]);
  }

  return cols;
}

function findEntryAndUpdate(
  day: BoardDay[],
  update: { lesson: string; boardId: string; data: {} }
) {
  let currentBoard = [...day];
  for (let i = 0; i < currentBoard.length; i++) {
    for (let j = 0; j < currentBoard[i].length; j++) {
      if (String(currentBoard[i][j].lessonId) === String(update.lesson)) {
        for (let k = 0; k < currentBoard[i][j].entries.length; k++) {
          if (currentBoard[i][j].entries[k].boardId == update.boardId) {
            console.log("found entry", {
              ...currentBoard[i][j].entries[k],
              ...update.data,
            });
            currentBoard[i][j].entries[k] = {
              ...currentBoard[i][j].entries[k],
              ...update.data,
            };
            break;
          }
        }
      }
    }
  }

  return currentBoard;
}

function DayView() {
  const [countdown, setCountdown] = useState(30);
  const [errorMessage, setErrorMessage] = useState("");
  const [day, setDay] = useState<BoardDay[]>();
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { client, loadingWeb } = useWebsocketContext();

  const dayRef = useRef(day);
  console.log();

  useEffect(() => {
    dayRef.current = day; // Update ref whenever `day` changes
  }, [day]);

  async function getBoard(refresh: boolean) {
    setErrorMessage("");
    let dateToFetch = date ?? dayjs();
    if (refresh || date === null) {
      try {
        const result: {
          boardDate: { Time: string; Valid: boolean };
          page: number;
        } = await HttpClient.get("/board/control");
        dateToFetch = result.boardDate.Valid
          ? dayjs(result.boardDate.Time.split("T")[0])
          : dayjs();

        setDate(dateToFetch);
        setCurrentPage(result.page);
        return;
      } catch {
        setDate(dateToFetch ?? dayjs());
        return;
      }
    }

    try {
      const result: BoardDay = await HttpClient.get(
        `/board/day/${dayString[dateToFetch.day()]}`,
        {},
        {
          date: dateToFetch.format("YYYY-MM-DD"),
        }
      );

      setDay(determineColums(result));
      setCountdown(30);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  function handleMessage(
    payload: mqtt5.Payload | undefined,
    board: BoardDay[]
  ) {
    if (typeof payload === "string") {
      const obj = JSON.parse(payload);
      console.log(obj.type, obj);

      if (obj.type === "dateChange") {
        setDate(dayjs(obj.date));
        setCurrentPage(1);
      }

      if (obj.type === "pageChange") {
        setCurrentPage(Number(obj.page));
      }

      if (obj.type === "horseChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          boardId: obj.data.boardId,
          data: {
            horseName: obj.data.horseName,
            horseId: obj.data.horseId,
            horseIn: false,
            feedAfterClass: false,
            horseChangedTime: new Date().toISOString(),
            saddle: {
              saddle: "",
              pads: "",
              additions: "",
            },
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "horseInChange" && board) {
        console.log("setting horse in", obj);
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lessonId,
          boardId: obj.data.boardId,
          data: {
            horseIn: obj.data.horseIn,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "horseSpecialFeedChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          boardId: obj.data.boardId,
          data: {
            feedAfterClass: obj.data.feedAfterClass,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "blanketAfterClass" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          boardId: obj.data.boardId,
          data: {
            blanket: obj.data.blanket,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "riderAbsentChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          boardId: obj.data.boardId,
          data: {
            riderAbsent: obj.data.riderAbsent,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "horseNoteChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lessonId,
          boardId: obj.data.boardId,
          data: {
            notes: obj.data.notes,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "horseSaddleChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          boardId: obj.data.boardId,
          data: {
            saddle: {
              saddle: obj.data.saddle,
              pads: obj.data.pads,
              additions: obj.data.additions,
            },
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "lessonNoteChange" && day) {
        let currentBoard = [...day];
        for (let i = 0; i < currentBoard.length; i++) {
          for (let j = 0; j < currentBoard[i].length; j++) {
            if (
              String(currentBoard[i][j].lessonId) === String(obj.data.lesson)
            ) {
              currentBoard[i][j].lessonNotes = obj.data.notes;
            }
          }
        }
        setDay(currentBoard);
      }
    } else {
      console.log("invalid message", payload);
    }
  }

  useEffect(() => {
    getBoard(false);
  }, [date]);

  useEffect(() => {
    if (countdown <= 0) return;

    const timerId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0) {
      getBoard(true);
    }
  }, [countdown]);

  useEffect(() => {
    const messageListener = (eventData: mqtt5.MessageReceivedEvent): void => {
      // Whenever a message is received, use the updated dayRef.current
      handleMessage(
        toUtf8(eventData.message.payload as Buffer),
        dayRef.current!
      );
    };

    // Attach the listener if no existing listener
    if (client && client.listeners("messageReceived").length === 0) {
      client.on("messageReceived", messageListener);
    }

    // Clean up listener when component unmounts or dependencies change
    return () => {
      if (client && client.listeners("messageReceived").length > 0) {
        client.off("messageReceived", messageListener);
      }
    };
  }, [client]);

  if (loadingWeb || client === null || !day) {
    return null;
  }

  return (
    <Box margin={"1em"} marginLeft={"2em"} marginRight={"2em"}>
      <Grid item>
        <Typography
          fontWeight={"bold"}
          variant="h2"
          gutterBottom
          align="center"
          sx={{ margin: "0px" }}
        >
          {date ? dayString[date.day()] : ""}
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <DayViewColumn lessons={day[currentPage - 1]} />
        </Grid>
        <Grid item xs={6}>
          <DayViewColumn lessons={day[currentPage]} />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Typography
            fontWeight={"bold"}
            variant="h6"
            gutterBottom
            align="center"
            sx={{ margin: "0px" }}
          >
            Refreshing data in: {countdown} secs
          </Typography>
          <Typography
            fontWeight={"bold"}
            variant="h6"
            gutterBottom
            align="center"
            sx={{ margin: "0px", color: "red" }}
          >
            {errorMessage
              ? `Failed to fetch data on last refresh: ${errorMessage}`
              : ""}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DayView;
