import { ChangeEvent, useEffect, useState } from "react";

import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import {
  displayRiderLevel,
  displayRiderName,
  formatTime,
} from "../helpers/utils";
import dayjs from "dayjs";
import CheckIcon from "@mui/icons-material/Check";
import { BoardDay } from "./DayView";

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function concatIfBothNotEmpty(str1: string, str2: string) {
  if (str1.trim() !== "" && str2.trim() !== "") {
    return str1 + ", " + str2;
  } else if (str1) {
    return str1;
  }

  return str2;
}

function isWithinLast3Hours(date: string): boolean {
  const now = dayjs();
  const threeHoursAgo = now.subtract(3, "hour");
  return dayjs(date).isAfter(threeHoursAgo);
}

function DayViewColumn(props: { lessons?: BoardDay }) {
  return (
    <Box style={{ height: "85vh" }}>
      {props.lessons?.map((lesson) => {
        return (
          <Grid key={lesson.lessonTime} padding={"0.25em"} rowSpacing={"0.5em"}>
            <Box sx={{ backgroundColor: "#588157", borderRadius: "0.25em" }}>
              <Grid container>
                <Grid item xs={3} padding={"0.25em"} paddingLeft={"0.5em"}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {formatTime(lesson.lessonTime)}
                  </Typography>
                </Grid>
                <Grid item xs={4} padding={"0.25em"} paddingLeft={"0.5em"}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {lesson.lessonLevel != undefined && lesson.lessonLevel >= 0
                      ? displayRiderLevel(lesson.lessonLevel)
                      : lesson.program}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    color: "white",
                  }}
                >
                  {lesson.lessonNotes}
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                {lesson.entries.map((entry, index2) => {
                  let saddle = entry.saddle.saddle;
                  if (entry.saddle.pads || entry.saddle.additions) {
                    saddle += " w/";
                  }

                  saddle += concatIfBothNotEmpty(
                    entry.saddle.pads.toLowerCase(),
                    entry.saddle.additions.toLowerCase()
                  );

                  let notes = [];
                  if (entry.entryModifications === "Hack Ride") {
                    notes.push("HR");
                  }
                  if (entry.entryModifications === "Eval") {
                    notes.push("Eval");
                  }
                  if (entry.feedAfterClass) {
                    notes.push("Special Feed");
                  }
                  if (entry.blanket) {
                    notes.push("Blanket");
                  }
                  notes.push(entry.notes);
                  const notesString = notes.join(", ");
                  console.log(notesString);

                  return (
                    <Grid
                      container
                      key={index2}
                      padding={"0.25em"}
                      paddingLeft={"0.5em"}
                      sx={{
                        backgroundColor:
                          index2 % 2 === 0 ? "#a3b18a" : "#dad7cd",
                        borderRadius:
                          index2 === 0
                            ? "0.25em 0.25em 0em 0em"
                            : index2 === lesson.entries.length - 1
                            ? "0em 0em 0.25em 0.25em"
                            : undefined,
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography variant="h6">{saddle}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="h5"
                          sx={{
                            color:
                              entry.horseChangedTime &&
                              isWithinLast3Hours(entry.horseChangedTime)
                                ? "blue"
                                : "black",
                          }}
                        >
                          {entry.horseName}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="h5">
                          {displayRiderName(entry.firstName, entry.lastName)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {entry.riderAbsent ? (
                          <Typography variant="h6">RA</Typography>
                        ) : entry.horseIn ? (
                          <CheckIcon />
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {notesString}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </Box>
  );
}

export default DayViewColumn;
